import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { JSONContent } from '@tiptap/core'
import isEqual from 'lodash/isEqual'
import { memo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  GetThemesDocument,
  useCreateThemeMutation,
  useUpdateThemeMutation,
} from 'modules/api'
import { SegmentEvents, useAnalytics } from 'modules/segment'
import { selectDoc } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { useStateDelayed } from 'utils/hooks'

import { EMPTY_THEME_NAME } from '../constants'
import { ThemeDispatch, ThemeState } from '../themeReducer/types'
import { Theme } from '../types'
import { ThemeEditor, ThemeEditorHeader } from './ThemeEditor'
interface ThemeEditorDrawerProps {
  isOpen: boolean
  onClose: () => void
  state: ThemeState
  dispatch: ThemeDispatch
  docContent?: JSONContent
  onThemeCreated?: (newTheme: Theme) => void
  disableDrawerTransition?: boolean
}

const nullableThemeAttributes = [
  'headingFont',
  'bodyFont',
  'accentColor',
  'logoUrl',
]

export const ThemeEditorDrawer = memo(
  ({
    isOpen,
    onClose,
    state,
    dispatch,
    docContent,
    onThemeCreated,
    disableDrawerTransition = false,
  }: ThemeEditorDrawerProps) => {
    const analytics = useAnalytics()
    const toast = useToast()
    const doc = useSelector(selectDoc)
    const isOpenDelayed = useStateDelayed(isOpen, 500)
    const { user, currentWorkspace } = useUserContext()
    // Prevent the theme name input from getting automatically focused
    const initialFocusRef = useRef<HTMLButtonElement | null>(null)
    // When the drawer transition is enabled, allow the animation to
    // complete while showing a spinner before rendering the editor.
    // This is a performance hack avoid sluggish animations.
    const shouldShowThemeEditor = disableDrawerTransition
      ? isOpen
      : isOpenDelayed

    const [createThemeMutation, { loading }] = useCreateThemeMutation()
    const [updateThemeMutation, { loading: updateLoading }] =
      useUpdateThemeMutation()

    const onCancel = useCallback(() => {
      dispatch({ type: 'THEME_RESET' })
      onClose()
    }, [dispatch, onClose])

    const onSave = useCallback(() => {
      if (!user || !currentWorkspace) return
      if (!state.theme) return
      if (!state.theme.name) {
        dispatch({
          type: 'THEME_VALIDATION_ERROR',
          data: {
            errorMessage: 'Theme name is required.',
          },
        })
        return
      }

      if (state.theme.id === 'new') {
        // pull out the things the API won't take
        const input = {
          ...state.theme,
          workspaceId: currentWorkspace.id,
        }
        const {
          id: _id,
          archived: _archived,
          fonts: _fonts,
          ...createInput
        } = input
        createThemeMutation({
          variables: { input: createInput },
          update: (cache, { data }) => {
            if (!data?.createTheme) return

            // Write the newly created theme into the cache so it shows up in the
            // list without refreshing
            cache.writeQuery({
              query: GetThemesDocument,
              variables: {
                workspaceId: input.workspaceId,
                archived: false,
              },
              data: {
                themes: [data.createTheme],
              },
            })
          },
          optimisticResponse: {
            createTheme: {
              __typename: 'Theme',
              ...input,
              createdTime: new Date().toISOString(),
              updatedTime: new Date().toISOString(),
            },
          },
        })
          .then(({ data }) => {
            if (data?.createTheme) {
              analytics?.track(SegmentEvents.THEME_CREATED, {
                theme_id: data?.createTheme?.id,
                type: 'new',
              })
              onThemeCreated?.(data.createTheme)
            }
            toast({
              title: `Theme "${input.name}" has been saved`,
              status: 'success',
              duration: 3000,
              position: 'top',
              isClosable: true,
            })
            onCancel()
          })
          .catch((e) => {
            if (
              e.graphQLErrors &&
              e.graphQLErrors.length > 0 &&
              e.graphQLErrors[0].code === 'duplicate_theme_name'
            ) {
              dispatch({
                type: 'THEME_VALIDATION_ERROR',
                data: { errorMessage: e.message },
              })
            } else {
              console.error(`Couldn't create theme ${input.name} error: ${e}`)
              toast({
                title: `Couldn't create theme. ${e}`,
                status: 'error',
                duration: 3000,
                position: 'top',
                isClosable: false,
              })
            }
          })
      } else {
        // pull out the things the API won't take
        const {
          // @ts-ignore
          __typename,
          archived: _archived,
          createdTime: _createdTime,
          updatedTime: _updatedTime,
          createdBy: _createdBy,
          fonts: _fonts,
          ...updateInput
        } = state.theme

        // if these are set but undefined for an update,
        // that means we want to clear them and they should be null
        for (const attr of nullableThemeAttributes) {
          if (attr in updateInput && updateInput[attr] === undefined) {
            updateInput[attr] = null
          }
        }

        updateThemeMutation({
          variables: { input: updateInput },
          update: (cache, { data }) => {
            if (!data?.updateTheme) return

            // Write the updated theme into the cache so it shows up in the
            // list without refreshing
            cache.writeQuery({
              query: GetThemesDocument,
              variables: {
                workspaceId: state.theme.workspaceId,
                archived: false,
              },
              data: {
                themes: [data.updateTheme],
              },
            })
          },
          optimisticResponse: {
            updateTheme: {
              __typename: 'Theme',
              ...state.theme,
              createdTime: new Date().toISOString(),
              updatedTime: new Date().toISOString(),
            },
          },
        })
          .then(() => {
            analytics?.track(SegmentEvents.THEME_UPDATED, {
              theme_id: state.theme.id,
            })
            toast({
              title: `Theme ${state.theme.name} has been updated`,
              status: 'success',
              duration: 3000,
              position: 'top',
              isClosable: true,
            })
            onCancel()
          })
          .catch((e) => {
            if (
              e.graphQLErrors &&
              e.graphQLErrors[0].code === 'duplicate_theme_name'
            ) {
              dispatch({
                type: 'THEME_VALIDATION_ERROR',
                data: { errorMessage: e.message },
              })
            } else {
              console.error(
                `Couldn't update theme ${state.theme.name} error: ${e}`
              )
              toast({
                title: `Couldn't update theme. ${e}`,
                status: 'error',
                duration: 3000,
                position: 'top',
                isClosable: false,
              })
            }
          })
      }
    }, [
      user,
      currentWorkspace,
      dispatch,
      state?.theme,
      createThemeMutation,
      toast,
      onCancel,
      analytics,
      onThemeCreated,
      updateThemeMutation,
    ])
    const isDirty =
      state.theme.id === 'new' || !isEqual(state.theme, state.originalTheme)

    return (
      <Drawer
        placement="bottom"
        onClose={onCancel}
        isOpen={isOpen}
        isFullHeight={true}
        initialFocusRef={initialFocusRef}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius="xl"
          h="calc(var(--100vh) - 24px)"
          // Prevent Chakra from animating the drawer. This is necessary because
          // background-attachment: fixed seems to be incompatible with transforms
          // and Chakra chose not to expose a transition setting :(
          // https://github.com/chakra-ui/chakra-ui/issues/4423
          transform={disableDrawerTransition ? 'none !important' : undefined}
        >
          <DrawerHeader borderBottom="1px solid #000" borderColor="gray.200">
            <Flex justify-content="space-between" alignItems="flex-end">
              <Box flex={1}>
                <ThemeEditorHeader
                  name={state.theme?.name || EMPTY_THEME_NAME}
                  doc={doc}
                  theme={state.theme}
                  dispatch={dispatch}
                  themeValidationError={state.themeValidationError}
                />
              </Box>
              <ButtonGroup>
                <Button variant="ghost" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  isLoading={loading || updateLoading}
                  isDisabled={!isDirty}
                  onClick={onSave}
                  data-testid="custom-theme-save"
                >
                  Save theme
                </Button>
              </ButtonGroup>
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0} h="100%">
            {shouldShowThemeEditor ? (
              <ThemeEditor
                theme={state.theme}
                workspaceId={currentWorkspace?.id}
                dispatch={dispatch}
                docContent={docContent}
                doc={doc}
              />
            ) : (
              <LoadingState />
            )}
          </DrawerBody>
          <DrawerCloseButton ref={initialFocusRef} />
        </DrawerContent>
      </Drawer>
    )
  }
)

ThemeEditorDrawer.displayName = 'ThemeEditorDrawer'

const LoadingState = () => {
  return (
    <Box
      flexDirection="column"
      flex="1"
      w="100%"
      h="100%"
      bg="gray.100"
      inset={0}
    >
      <Center h="100%">
        <Spinner />
      </Center>
    </Box>
  )
}
