import { FormControl } from '@chakra-ui/react'

import { LogoPicker } from '../LogoPicker'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigLogoPanel = () => {
  const { pop, state, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state
  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <FormControl>
        <LogoPicker
          value={theme.logoUrl || null}
          updateValue={(src) =>
            dispatch({
              type: 'THEME_UPDATE_THEME_PROPS',
              data: { themeProps: { logoUrl: src ?? undefined } },
            })
          }
        />
      </FormControl>
    </ThemeConfigNavigationWrapper>
  )
}
