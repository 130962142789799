import { Box, Button, Flex, FlexProps } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { memo } from 'react'

export const formatTestId = (label: string) =>
  label
    .replace(/^\d+\.\s*/, '')
    .replaceAll(/\s/g, '-')
    .toLowerCase()

type ThemeConfigNavigationMenuButtonProps = {
  icon: FontAwesomeIconProps['icon']
  label: string
  onClick: () => void
} & FlexProps

export const resetButtonStyles = {
  width: '100%',
  variant: 'unstyled',
  borderRadius: 'revert',
  height: 'revert',
  justifyContent: 'flex-start',
}

export const ThemeConfigNavigationMenuButton = memo(
  ({ icon, label, onClick, ...rest }: ThemeConfigNavigationMenuButtonProps) => {
    return (
      <Flex
        data-testid={`theme-config-menu-button-${formatTestId(label)}`}
        as={Button}
        {...resetButtonStyles}
        onClick={onClick}
        p={4}
        borderBottomWidth="1px"
        _hover={{
          bg: 'trueblue.50',
        }}
        {...rest}
      >
        <Box color="trueblue.500">
          <FontAwesomeIcon icon={icon} fixedWidth />
        </Box>

        <Box fontWeight="600" ml={2} mr="auto">
          {label}
        </Box>

        <Box color="gray.600">
          <FontAwesomeIcon icon={regular('chevron-right')} />
        </Box>
      </Flex>
    )
  }
)

ThemeConfigNavigationMenuButton.displayName = 'ThemeConfigNavigationMenuButton'
