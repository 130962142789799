import {
  Box,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  useEditableControls,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'

import { EMPTY_THEME_NAME } from '../constants'

type ThemeNameInputEditableProps = {
  value: string
  onSave: (str?: string) => void
  themeValidationError: string | null
}

export const ThemeNameEditableInput = ({
  value,
  onSave,
  themeValidationError,
}: ThemeNameInputEditableProps) => {
  return (
    <FormControl isInvalid={Boolean(themeValidationError)}>
      <Editable
        defaultValue={value}
        placeholder={EMPTY_THEME_NAME}
        onSubmit={onSave}
        submitOnBlur
        isPreviewFocusable
        fontSize="3xl"
      >
        <HStack minHeight={14}>
          <EditablePreview data-testid="custom-theme-name-preview" />
          <Input
            as={EditableInput}
            w={{ sm: '50%', lg: '40%', xl: '30%', '2xl': '20%' }}
            data-testid="custom-theme-name-input"
          />
          <EditableControls />
        </HStack>
      </Editable>
      {themeValidationError && (
        <FormErrorMessage fontWeight="500" pb={2}>
          {themeValidationError}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

const EditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm" spacing={0}>
      <GammaTooltip label="Cancel">
        <IconButton
          variant="ghost"
          aria-label="Cancel"
          icon={<FontAwesomeIcon icon={regular('close')} />}
          {...getCancelButtonProps()}
        />
      </GammaTooltip>
      <GammaTooltip label="Save theme name">
        <IconButton
          variant="ghost"
          aria-label="Save theme name"
          icon={<FontAwesomeIcon icon={regular('check')} />}
          {...getSubmitButtonProps()}
          data-testid="save-theme-name-button"
        />
      </GammaTooltip>
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <GammaTooltip label="Edit theme name">
        <Box>
          <IconButton
            variant="ghost"
            aria-label="Edit theme name"
            size="sm"
            icon={<FontAwesomeIcon icon={regular('pen-to-square')} />}
            {...getEditButtonProps()}
            // Chakra uses the ref to re-focus the edit button when not editing, which causes the tooltip to stick around
            // https://github.com/chakra-ui/chakra-ui/blob/c77973070dac3468d701ccb2e7b8ff87c8d430f4/packages/components/editable/src/use-editable.ts#L139
            ref={null}
          />
        </Box>
      </GammaTooltip>
    </Flex>
  )
}
