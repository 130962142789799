import React, { useState } from 'react'

import { NavigationStack } from 'modules/navigation_stack/NavigationStack'

import { ThemeDispatch, ThemeState } from '../../themeReducer/types'
import { FontMap, FontPickerFonts } from '../../types'
import { ThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationMenu } from './ThemeConfigNavigationMenu'

type ThemeConfigNavigationPanelProps = {
  theme: ThemeState['theme']
  dispatch: ThemeDispatch
  fonts: FontPickerFonts
  fontsMap: FontMap
}

export const ThemeConfigNavigationPanel = ({
  theme,
  dispatch,
  fonts,
  fontsMap,
}: ThemeConfigNavigationPanelProps) => {
  const [label, setLabel] = useState('')

  return (
    <ThemeConfigNavigationContext.Provider
      value={{
        state: { theme, fontState: { fonts, fontsMap } },
        dispatch,
        label,
        setLabel,
      }}
    >
      <NavigationStack InitialComponent={ThemeConfigNavigationMenu} />
    </ThemeConfigNavigationContext.Provider>
  )
}
