import { Box, BoxProps } from '@chakra-ui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { NavigationStackContext } from './NavigationStackContext'
import { NavigationStackComponent } from './types'

const MotionBox = motion(Box)

const variants: Variants = {
  enter: (direction: StackDirection) => ({
    position: 'absolute',
    width: '100%',
    x: direction === 'push' ? '100%' : '-100%',
    opacity: 0,
  }),
  center: {
    width: '100%',
    position: 'static',
    x: 0,
    opacity: 1,
  },
  exit: (direction: StackDirection) => ({
    width: '100%',
    position: 'absolute',
    x: direction === 'push' ? '-100%' : '100%',
    opacity: 0,
  }),
}

type StackDirection = 'push' | 'pop'

type NavigationStackProps = {
  containerProps?: BoxProps
  motionProps?: BoxProps
  InitialComponent?: NavigationStackComponent
}

export const NavigationStack = ({
  containerProps,
  motionProps,
  InitialComponent,
}: NavigationStackProps) => {
  const [stack, setStack] = useState<Array<NavigationStackComponent>>(
    InitialComponent ? [InitialComponent] : []
  )
  const directionRef = useRef<StackDirection>('push')

  const pop = useCallback(() => {
    directionRef.current = 'pop'
    setStack([...stack.slice(0, -1)])
  }, [stack])

  const push = useCallback(
    (comp: NavigationStackComponent) => {
      directionRef.current = 'push'
      setStack([...stack, comp])
    },
    [stack]
  )

  const Comp = stack[stack.length - 1]

  useEffect(() => {
    if (stack.length === 0 && InitialComponent) {
      push(InitialComponent)
    }
  }, [InitialComponent, push, stack])

  if (!Comp) return null

  return (
    <NavigationStackContext.Provider value={{ push, pop }}>
      <Box {...(containerProps || {})} position="relative">
        <AnimatePresence initial={false} custom={directionRef.current}>
          <MotionBox
            variants={variants}
            key={stack.length}
            custom={directionRef.current}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              default: { duration: 0.2 },
            }}
            {...(motionProps || {})}
          >
            <Comp />
          </MotionBox>
        </AnimatePresence>
      </Box>
    </NavigationStackContext.Provider>
  )
}
