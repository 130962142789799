import { Alert, AlertIcon, Text } from '@chakra-ui/react'
import { DOC_DISPLAY_NAME_PLURAL } from '@gamma-app/ui'
import { useCallback } from 'react'

import {
  getThemeBackgroundOrDefault,
  isThemeDark,
} from 'modules/theming/utils/utils'
import { ThemeBackgroundPanel } from 'modules/tiptap_editor/components/panels/ThemeBackgroundPanel'
import { BackgroundOptions } from 'modules/tiptap_editor/styles/backgroundStyles'

import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigBackgroundPanel = () => {
  const { state, pop, dispatch, label } = useThemeConfigNavigationContext()

  const background = getThemeBackgroundOrDefault(state.theme)
  const isDark = isThemeDark(state.theme)

  const updateThemeBackground = useCallback(
    (bg?: BackgroundOptions) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_CONFIG',
        data: { themeConfig: { background: bg } },
      }),
    [dispatch]
  )

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <ThemeBackgroundPanel
        setBackground={updateThemeBackground}
        background={background}
        defaultMessage={
          <Alert fontSize="xs">
            <AlertIcon />
            <Text>
              Choose an option above to set a default background for all{' '}
              {DOC_DISPLAY_NAME_PLURAL} using this theme.
            </Text>
          </Alert>
        }
        isDark={isDark}
      />
    </ThemeConfigNavigationWrapper>
  )
}
