import cloneDeep from 'lodash/cloneDeep'
import React from 'react'

import { EMPTY_THEME } from '../constants'
import {
  ThemeAction,
  ThemeDispatch,
  ThemeReducerState,
  ThemeState,
} from './types'

export const initialThemeState: ThemeState = {
  theme: EMPTY_THEME,
  themeValidationError: null,
  status: 'new',
  originalTheme: null,
}

function themeReducer(state: ThemeState, action: ThemeAction): ThemeState {
  switch (action.type) {
    case 'NEW_THEME': {
      return cloneDeep(initialThemeState)
    }
    case 'THEME_RESET': {
      return cloneDeep(initialThemeState)
    }
    case 'SET_THEME_EDITING': {
      const theme = cloneDeep(action.data.theme)
      return {
        ...state,
        status: 'editing',
        theme,
        originalTheme: theme,
      }
    }
    case 'THEME_UPDATE_THEME_PROPS': {
      const { theme } = state
      const { themeProps } = action.data
      return {
        ...state,
        theme: { ...theme, ...themeProps },
      }
    }
    case 'THEME_UPDATE_THEME_CONFIG': {
      const { theme } = state
      const { themeConfig } = action.data
      return {
        ...state,
        theme: {
          ...theme,
          config: {
            ...theme.config,
            ...themeConfig,
          },
        },
      }
    }
    case 'THEME_UPDATE_THEME_NAME': {
      const { theme } = state
      const { name } = action.data
      return {
        ...state,
        theme: {
          ...theme,
          name,
        },
        themeValidationError: null,
      }
    }
    case 'THEME_VALIDATION_ERROR': {
      const { errorMessage } = action.data
      return {
        ...state,
        themeValidationError: errorMessage,
      }
    }
    case 'THEME_VALIDATION_ERROR_RESET': {
      return {
        ...state,
        themeValidationError: null,
      }
    }
  }
}

export function useThemeReducer(): [ThemeReducerState, ThemeDispatch] {
  return React.useReducer(themeReducer, initialThemeState)
}
