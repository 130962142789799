// https://staging.gamma.app/docs/Theme-preview-deck-h4ohn9zqvfteqbq
export const EXAMPLE_DOC_V2 = {
  type: 'doc',
  content: [
    {
      type: 'document',
      attrs: {
        docId: 'h4ohn9zqvfteqbq',
        theme: 'default-light',
        background: {
          type: 'none',
        },
        docFlags: {
          cardLayoutsEnabled: true,
        },
      },
      content: [
        {
          type: 'card',
          attrs: {
            id: '3yibcs4t5uez0zc',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'top',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    uploadStatus: 0,
                    showPlaceholder: false,
                    src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/a8bca02181764fc1b12f9f1d36a3e3ee/optimized/Solar_System_true_color.jpg',
                    meta: {
                      width: 1920,
                      height: 1080,
                      date_file_modified: '2023/01/27 17:56:26 GMT',
                      aspect_ratio: 1.778,
                      has_clipping_path: false,
                      frame_count: 1,
                      colorspace: 'sRGB',
                      has_transparency: false,
                      average_color: '#1a1a19',
                      orientation: 'Horizontal (normal)',
                    },
                    name: 'Solar_System_true_color.jpg',
                    source: 'image.custom',
                  },
                  source: 'image.custom',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'title',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'The Solar System',
                    },
                  ],
                },
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 3,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'An overview of our galactic home',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'rzuvwh66nklqv82',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'left',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://images.unsplash.com/photo-1531306728370-e2ebd9d7bb99?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwxMXx8c29sYXIlMjBzeXN0ZW18ZW58MXx8fHwxNjc0ODQyOTAx&ixlib=rb-4.0.3&w=2911&q=25',
                    tempUrl:
                      'https://images.unsplash.com/photo-1531306728370-e2ebd9d7bb99?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwxMXx8c29sYXIlMjBzeXN0ZW18ZW58MXx8fHwxNjc0ODQyOTAx&ixlib=rb-4.0.3&q=80&w=200',
                    meta: {
                      height: 4366,
                      width: 2911,
                    },
                    query: 'solar system',
                  },
                  source: 'image.unsplash',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'What is the solar system?',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: 'start',
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Our planetary system is located in an outer spiral arm of ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'https://en.wikipedia.org/wiki/Milky_Way',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/ESO-VLT-Laser-phot-33a-07.jpg/1200px-ESO-VLT-Laser-phot-33a-07.jpg',
                              height: 797,
                              width: 1200,
                            },
                            embed: null,
                            meta: {
                              title: 'Milky Way',
                              description:
                                'The Milky Way is the galaxy that includes the Solar System, with the name describing the galaxy\'s appearance from Earth: a hazy band of light seen in the night sky formed from stars that cannot be individually distinguished by the naked eye. The term Milky Way is a translation of the Latin via lactea, from the Greek γαλακτικός κύκλος, meaning "milky circle". From Earth, the Milky Way appears as a band because its disk-shaped structure is viewed from within. Galileo Galilei first resolved the band of light into individual stars with his telescope in 1610. Until the early 1920s, most astronomers thought that the Milky Way contained all the stars in the Universe. Following the 1920 Great Debate between the astronomers Harlow Shapley and Heber Doust Curtis, observations by Edwin Hubble showed that the Milky Way is just one of many galaxies.',
                              site: 'Wikipedia',
                              medium: 'link',
                              canonical:
                                'https://en.wikipedia.org/wiki/Milky_Way',
                              author: 'Wikipedia',
                              date: '2023-01-27T10:57:25.000Z',
                              icon: 'https://en.wikipedia.org/static/apple-touch/wikipedia.png',
                            },
                          },
                        },
                      ],
                      text: 'the Milky Way galaxy',
                    },
                    {
                      type: 'text',
                      text: '.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: 'start',
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Our solar system consists of our star, the Sun, and everything bound to it by gravity – the planets Mercury, Venus, Earth, Mars, Jupiter, Saturn, Uranus, and Neptune; ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'footnoteLabel',
                          attrs: {
                            noteId: '-U3BA',
                          },
                        },
                      ],
                      text: 'dwarf planets such as Pluto',
                    },
                    {
                      type: 'footnote',
                      attrs: {
                        noteId: '-U3BA',
                      },
                      content: [
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'In 2006, the International Astronomical Union formally redefined the term ',
                            },
                            {
                              type: 'text',
                              marks: [
                                {
                                  type: 'italic',
                                },
                              ],
                              text: 'planet',
                            },
                            {
                              type: 'text',
                              text: '. The new definition excluded dwarf planets such as Pluto — however, many planetary astronomers still consider Pluto to be a planet.',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'See the ',
                            },
                            {
                              type: 'text',
                              marks: [
                                {
                                  type: 'link',
                                  attrs: {
                                    href: 'https://en.wikipedia.org/wiki/Pluto',
                                    source: 'embed.custom',
                                    thumbnail: {
                                      src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Pluto_in_True_Color_-_High-Res.jpg/1200px-Pluto_in_True_Color_-_High-Res.jpg',
                                      height: 1200,
                                      width: 1200,
                                    },
                                    embed: null,
                                    meta: {
                                      title: 'Pluto',
                                      description:
                                        "Pluto is a dwarf planet in the Kuiper belt, a ring of bodies beyond the orbit of Neptune. It is the ninth-largest and tenth-most-massive known object to directly orbit the Sun. It is the largest known trans-Neptunian object by volume, by a small margin, but is slightly less massive than Eris. Like other Kuiper belt objects, Pluto is made primarily of ice and rock and is much smaller than the inner planets. Compared to Earth's moon, Pluto has only one sixth its mass and one third its volume.",
                                      site: 'Wikipedia',
                                      medium: 'link',
                                      canonical:
                                        'https://en.wikipedia.org/wiki/Pluto',
                                      author: 'Wikipedia',
                                      date: '2023-01-26T06:40:56.000Z',
                                      icon: 'https://en.wikipedia.org/static/apple-touch/wikipedia.png',
                                    },
                                  },
                                },
                              ],
                              text: 'Wikipedia article on Pluto ',
                            },
                            {
                              type: 'text',
                              text: 'for more.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'text',
                      text: '; dozens of moons; and millions of asteroids, comets, and meteoroids. ',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: 'start',
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Beyond our own solar system, we have discovered thousands of planetary systems orbiting other stars in the Milky Way.',
                    },
                  ],
                },
                {
                  type: 'calloutBox',
                  attrs: {
                    variant: 'note',
                    icon: true,
                  },
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: null,
                      },
                      content: [
                        {
                          type: 'text',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          text: 'Why is it called the solar system?',
                        },
                      ],
                    },
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: null,
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'There are many planetary systems like ours in the universe, with planets orbiting a host star. Our planetary system is called “the solar system” because we use the word “solar” to describe things related to our star, after the Latin word for Sun, "solis."',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'jtxnjuc4tuajs2v',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'right',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://images.unsplash.com/photo-1454789548928-9efd52dc4031?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyfHxzcGFjZXxlbnwxfHx8fDE2NzQ4NDQ0NzA&ixlib=rb-4.0.3&w=3000&q=25',
                    tempUrl:
                      'https://images.unsplash.com/photo-1454789548928-9efd52dc4031?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyfHxzcGFjZXxlbnwxfHx8fDE2NzQ4NDQ0NzA&ixlib=rb-4.0.3&q=80&w=200',
                    meta: {
                      height: 3000,
                      width: 3000,
                    },
                    query: 'space',
                  },
                  source: 'image.unsplash',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'blockquote',
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: 'lg',
                      },
                      content: [
                        {
                          type: 'text',
                          text: "The stars don't look bigger, but they do look brighter.",
                        },
                      ],
                    },
                    {
                      type: 'paragraph',
                      attrs: {
                        horizontalAlign: null,
                        fontSize: 'lg',
                      },
                      content: [
                        {
                          type: 'text',
                          text: '— Sally Ride',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'x390h0oct9ylv6x',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Types of galaxies',
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'imagesText',
                    options: {
                      cellSize: 11,
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/eaf1a676dc0b47d6985feee97360c207/optimized/Abell_S740-_cropped_to_ESO_325-G004.jpg',
                          meta: {
                            width: 2389,
                            height: 2560,
                            date_file_created: '2007/02/05 17:04:43 GMT',
                            date_file_modified: '2023/01/27 19:52:34 GMT',
                            aspect_ratio: 0.933,
                            device_software: 'Adobe Photoshop CS2 Macintosh',
                            orientation: 'Horizontal (normal)',
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'RGB',
                            has_transparency: false,
                            average_color: '#281d1c',
                          },
                          name: 'Abell_S740,_cropped_to_ESO_325-G004.jpg',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Elliptical',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Ellipticals, which account for about one-third of galaxies observed, vary from nearly circular to very elongated.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/0e660a361761422888165fa948827368/optimized/M101_hires_STScI-PRC2006-10a.jpg',
                          meta: {
                            width: 1200,
                            height: 938,
                            date_file_modified: '2023/01/27 19:52:41 GMT',
                            aspect_ratio: 1.279,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'RGB',
                            has_transparency: false,
                            average_color: '#322f31',
                            orientation: 'Horizontal (normal)',
                          },
                          name: 'M101_hires_STScI-PRC2006-10a.jpg',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Spiral',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Spiral galaxies appear as flat, blue-white disks of stars, gas and dust with yellowish bulges in their centers. ',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'The Milky Way is a ',
                            },
                            {
                              type: 'text',
                              marks: [
                                {
                                  type: 'bold',
                                },
                              ],
                              text: 'barred spiral galaxy',
                            },
                            {
                              type: 'text',
                              text: '.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: {
                          source: 'image.custom',
                          uploadStatus: 0,
                          showPlaceholder: false,
                          src: 'https://cdn-staging.gamma.app/QWjS6fBfpt/f7317e37ecf3430d91950a73ebb3ea79/optimized/1200px-Irregular_galaxy_NGC_1427A_-captured_by_the_Hubble_Space_Telescope.jpg',
                          meta: {
                            width: 1200,
                            height: 1353,
                            date_file_modified: '2023/01/27 19:53:07 GMT',
                            aspect_ratio: 0.887,
                            has_clipping_path: false,
                            frame_count: 1,
                            colorspace: 'RGB',
                            has_transparency: false,
                            average_color: '#171b19',
                            orientation: 'Horizontal (normal)',
                          },
                          name: '1200px-Irregular_galaxy_NGC_1427A_(captured_by_the_Hubble_Space_Telescope).jpg',
                        },
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Irregular',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Irregular galaxies, which have very little dust, are neither disk-like nor elliptical. These galaxies are abundant in the early universe, before spirals and ellipticals developed.',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '4cr36odzl7ri9ji',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'behind',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://images.unsplash.com/photo-1504333638930-c8787321eee0?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyMXx8bmFzYXxlbnwxfHx8fDE2NzQ4NDUzMjI&ixlib=rb-4.0.3&w=3000&q=25',
                    tempUrl:
                      'https://images.unsplash.com/photo-1504333638930-c8787321eee0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyMXx8bmFzYXxlbnwxfHx8fDE2NzQ4NDUzMjI&ixlib=rb-4.0.3&q=80&w=200',
                    meta: {
                      height: 2000,
                      width: 3000,
                    },
                    query: 'nasa',
                  },
                  source: 'image.unsplash',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'A quick timeline',
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'timeline',
                    options: {
                      orientation: 'vertical',
                      twoSided: true,
                      hasLine: false,
                      numbered: false,
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: '13.8 billion years ago',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'The Big Bang forms the universe.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: '4.6 billion years ago',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'A group of protostars, one of which will become the Sun, form from a cloud of debris left by prior star explosions in the Milky Way.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              marks: [
                                {
                                  type: 'bold',
                                },
                              ],
                              text: '4.59 billion years ago',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'The giant planets Jupiter, Saturn, Uranus, and Neptune form around the protosun. At least Uranus and Neptune form closer to the Sun than where they are today. One or more ice giants may have also formed that were later ejected from the solar system.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: '3.8 to 3.5 billion years ago',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Life begins on Earth.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: '66 million years ago',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'A giant asteroid impacts Earth, triggering global climate change. Three quarters of life, including the dinosaurs, go extinct.',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'ofyxgxzt9dxodge',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Fun facts about the solar system',
                    },
                  ],
                },
                {
                  type: 'smartLayout',
                  attrs: {
                    variantKey: 'textBoxes',
                    options: {
                      twoSided: true,
                    },
                    fullWidthBlock: false,
                  },
                  content: [
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Meet me in the Orion Arm',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Our solar system orbits the center of the Milky Way galaxy at about 515,000 mph (828,000 kph). We’re in one of the galaxy’s four spiral arms.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'A long way round',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'It takes our solar system about 230 million years to complete one orbit around the galactic center.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: 'left',
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Good atmospheres',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: 'left',
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Our solar system is a region of space. It has no atmosphere. But it contains many worlds – including Earth – with many kinds of atmospheres.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: 'left',
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Many moons',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: 'left',
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'The planets of our solar system – and even some asteroids – hold more than 200 moons in their orbits.',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'smartLayoutCell',
                      attrs: {
                        options: {},
                        width: null,
                        image: null,
                        label: null,
                      },
                      content: [
                        {
                          type: 'heading',
                          attrs: {
                            horizontalAlign: null,
                            level: 3,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Life as we know it',
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                          attrs: {
                            horizontalAlign: null,
                            fontSize: null,
                          },
                          content: [
                            {
                              type: 'text',
                              text: 'Our solar system is the only one known to support life. So far, we only know of life on Earth, but we’re looking for more everywhere we can.',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'qlecwcdbfpvy2e5',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'top',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://images.unsplash.com/photo-1614724723258-ba209d44bc06?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyOHx8c29sYXIlMjBzeXN0ZW18ZW58MXx8fHwxNjc0ODQyOTAx&ixlib=rb-4.0.3&w=3000&q=25',
                    tempUrl:
                      'https://images.unsplash.com/photo-1614724723258-ba209d44bc06?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwyOHx8c29sYXIlMjBzeXN0ZW18ZW58MXx8fHwxNjc0ODQyOTAx&ixlib=rb-4.0.3&q=80&w=200',
                    meta: {
                      height: 2998,
                      width: 3000,
                    },
                    query: 'solar system',
                  },
                  source: 'image.unsplash',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Did you know you can check out the code that powered the Apollo 11?',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'The Apollo Guidance Computer (AGC) code has been publicly available for some time in the form of paper scans. However, in 2016, Chris Garry uploaded the entirety of the AGC to GitHub — and you can check it out!',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: "The code is full of easter eggs. Here's the name for the ignition file:",
                    },
                  ],
                },
                {
                  type: 'codeBlock',
                  attrs: {
                    language: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'BURN_BABY_BURN--MASTER_IGNITION_ROUTINE',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'And a file containing the code that ran the program for the keyboard and display system:',
                    },
                  ],
                },
                {
                  type: 'codeBlock',
                  attrs: {
                    language: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'PINBALL_GAME_BUTTONS_AND_LIGHTS',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                },
                {
                  type: 'buttonGroup',
                  attrs: {
                    horizontalAlign: 'center',
                  },
                  content: [
                    {
                      type: 'button',
                      attrs: {
                        href: 'https://github.com/chrislgarry/Apollo-11',
                        variant: 'solid',
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'Check out the full GitHub',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: '2k3i154y06sf6sq',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'right',
          },
          content: [
            {
              type: 'cardAccentLayoutItem',
              attrs: {
                itemId: 'accent',
                background: {
                  type: 'image',
                  image: {
                    src: 'https://images.unsplash.com/photo-1532798369041-b33eb576ef16?ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwzfHxzb2xhciUyMHN5c3RlbXxlbnwxfHx8fDE2NzQ4NDI5MDE&ixlib=rb-4.0.3&w=2242&q=25',
                    tempUrl:
                      'https://images.unsplash.com/photo-1532798369041-b33eb576ef16?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxOTgwNzN8MHwxfHNlYXJjaHwzfHxzb2xhciUyMHN5c3RlbXxlbnwxfHx8fDE2NzQ4NDI5MDE&ixlib=rb-4.0.3&q=80&w=200',
                    meta: {
                      height: 2802,
                      width: 2242,
                    },
                    query: 'solar system',
                  },
                  source: 'image.unsplash',
                },
              },
            },
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Want to learn more?',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'The universe is vast and so are the resources to learn more about it.',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  attrs: {
                    horizontalAlign: null,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Here are a couple places to start:',
                    },
                  ],
                },
                {
                  type: 'buttonGroup',
                  attrs: {
                    horizontalAlign: null,
                  },
                  content: [
                    {
                      type: 'button',
                      attrs: {
                        href: 'https://solarsystem.nasa.gov/solar-system/our-solar-system/in-depth/',
                        variant: 'solid',
                      },
                      content: [
                        {
                          type: 'text',
                          text: "NASA's Solar System website",
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'buttonGroup',
                  attrs: {
                    horizontalAlign: null,
                  },
                  content: [
                    {
                      type: 'button',
                      attrs: {
                        href: 'https://en.wikipedia.org/wiki/Solar_System',
                        variant: 'outline',
                      },
                      content: [
                        {
                          type: 'text',
                          text: 'The Solar System Wikipedia page',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'card',
          attrs: {
            id: 'mt9ue67xyxg15pv',
            previewContent: null,
            background: {
              type: 'none',
            },
            container: {},
            cardSize: 'default',
            layout: 'blank',
          },
          content: [
            {
              type: 'cardLayoutItem',
              attrs: {
                itemId: 'body',
                background: {},
              },
              content: [
                {
                  type: 'heading',
                  attrs: {
                    horizontalAlign: null,
                    level: 1,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Credits',
                    },
                  ],
                },
                {
                  type: 'bullet',
                  attrs: {
                    indent: 0,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Content from ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'https://solarsystem.nasa.gov/solar-system/our-solar-system/overview/',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://solarsystem.nasa.gov/system/stellar_items/image_files/162_feature_1600x900_our_ss_5.jpg',
                              height: 900,
                              width: 1600,
                            },
                            embed: null,
                            meta: {
                              description:
                                'An overview of our solar system in the Orion arm of the Milky Way galaxy.',
                              title: 'Our Solar System',
                              medium: 'article',
                              keywords:
                                "Planets, Sun, Venus, Earth, Earth's Moon, Moon, Satellite, Robot, Mars, Asteroid, Jupiter, Europa, Saturn, Enceladus, Titan, Uranus, Neptune, Dwarf Planet, Pluto, Eris, Our Solar System",
                              'theme-color': '#ffffff',
                              canonical:
                                'https://solarsystem.nasa.gov/solar-system/our-solar-system/overview',
                              date: '2022-07-05T13:43:31.000Z',
                              site: 'NASA Solar System Exploration',
                              icon: 'https://solarsystem.nasa.gov/apple-touch-icon.png',
                            },
                          },
                        },
                      ],
                      text: 'NASA',
                    },
                    {
                      type: 'text',
                      text: ', Wikipedia, National Geographic, ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'https://www.planetary.org/worlds/solar-system-timeline#:~:text=4.55%20billion%20years%20ago%3A%20Let,separates%20from%20its%20protostar%20siblings.',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://planetary.s3.amazonaws.com/web/assets/pictures/_1200x630_crop_center-center_82_none/early-earth-bombarded-asteroids.jpg?mtime=1610577784',
                              height: 630,
                              width: 1200,
                            },
                            embed: null,
                            meta: {
                              description:
                                'How did our solar system come to be, and when did key events that led to life on Earth occur?',
                              title: 'Solar System Timeline',
                              medium: 'link',
                              'theme-color': '#FFFFFF',
                              canonical:
                                'https://www.planetary.org/worlds/solar-system-timeline',
                              site: 'The Planetary Society',
                              icon: 'https://www.planetary.org/img/site/coast-228x228.png',
                            },
                          },
                        },
                      ],
                      text: 'The Planetary Society',
                    },
                  ],
                },
                {
                  type: 'bullet',
                  attrs: {
                    indent: 0,
                    fontSize: null,
                  },
                  content: [
                    {
                      type: 'text',
                      text: 'Images from ',
                    },
                    {
                      type: 'text',
                      marks: [
                        {
                          type: 'link',
                          attrs: {
                            href: 'https://en.wikipedia.org/wiki/Solar_System',
                            source: 'embed.custom',
                            thumbnail: {
                              src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Solar_System_true_color.jpg/1200px-Solar_System_true_color.jpg',
                              height: 675,
                              width: 1200,
                            },
                            embed: null,
                            meta: {
                              title: 'Solar System',
                              description:
                                "The Solar System is the gravitationally bound system of the Sun and the objects that orbit it. It formed 4.6 billion years ago from the gravitational collapse of a giant interstellar molecular cloud. The vast majority (99.86%) of the system's mass is in the Sun, with most of the remaining mass contained in the planet Jupiter. The four inner system planets—Mercury, Venus, Earth and Mars—are terrestrial planets, being composed primarily of rock and metal. The four giant planets of the outer system are substantially larger and more massive than the terrestrials. The two largest, Jupiter and Saturn, are gas giants, being composed mainly of hydrogen and helium; the next two, Uranus and Neptune, are ice giants, being composed mostly of volatile substances with relatively high melting points compared with hydrogen and helium, such as water, ammonia, and methane. All eight planets have nearly circular orbits that lie near the plane of Earth's orbit, called the ecliptic.",
                              site: 'Wikipedia',
                              medium: 'link',
                              canonical:
                                'https://en.wikipedia.org/wiki/Solar_System',
                              author: 'Wikipedia',
                              date: '2023-01-27T17:46:33.000Z',
                              icon: 'https://en.wikipedia.org/static/apple-touch/wikipedia.png',
                            },
                          },
                        },
                      ],
                      text: 'Wikipedia',
                    },
                    {
                      type: 'text',
                      text: ', NASA, and Unsplash',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
