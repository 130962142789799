import { useMemo } from 'react'

import {
  ExistingWorkspace,
  Font,
  FontSortField,
  SortDirection,
  ThemeFont,
  useGetGlobalFontsQuery,
  useGetWorkspaceFontsQuery,
} from 'modules/api'

import { FontMap } from './types'

export const useGetFonts = (workspaceId?: ExistingWorkspace['id']) => {
  const { data: globalFontData } = useGetGlobalFontsQuery()
  const { data: workspaceFontData } = useGetWorkspaceFontsQuery({
    variables: {
      workspaceId,
      sortBy: { field: FontSortField.Name, direction: SortDirection.Asc },
    },
    skip: !workspaceId,
  })

  const [globalFonts, workspaceFonts, fontsMap, themeFonts] = useMemo(() => {
    const global = globalFontData?.fonts || []
    const workspace = workspaceFontData?.fonts || []
    const allFonts = [...global, ...workspace]
    const map: FontMap = allFonts.reduce<{
      [key: string]: Font
    }>((acc, thisFont) => {
      acc[thisFont.id] = thisFont
      return acc
    }, {})

    const fontsForTheme: ThemeFont[] = allFonts.map((font) => ({
      id: font.id,
      name: font.name,
      url: font.cssUrl,
    }))

    return [global, workspace, map, fontsForTheme]
  }, [globalFontData?.fonts, workspaceFontData?.fonts])

  return {
    globalFonts,
    workspaceFonts,
    fontsMap,
    themeFonts,
  }
}
