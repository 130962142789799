import React, { useCallback, useState } from 'react'

import { UnsplashProvider } from 'modules/media'
import {
  MediaSourcesMap,
  MediaSourceType,
} from 'modules/tiptap_editor/extensions/media/MediaSources'

import { BackgroundOptions } from '../../styles/backgroundStyles'
import { BackgroundPanel } from './BackgroundPanel'
import { isNotAccentSource, isValidBackgroundSource } from './backgroundUtils'

export type ThemeBackgroundPanelProps = {
  setBackground: (background: BackgroundOptions) => void
  background: BackgroundOptions
  defaultMessage: React.ReactNode
  isDark?: boolean
  isValidSource?: (source: MediaSourceType) => boolean
}

const isValidThemeSource = (source: MediaSourceType) =>
  isValidBackgroundSource(source) && isNotAccentSource(source)

export const ThemeBackgroundPanel: React.FC<ThemeBackgroundPanelProps> = ({
  setBackground,
  background,
  defaultMessage,
  isDark,
  isValidSource,
}) => {
  const [currentSourceKey, setCurrentSourceKey] = useState(UnsplashProvider.key)
  const currentSource = MediaSourcesMap[currentSourceKey]

  const isValidThemeBackgroundSource = useCallback(
    (source: MediaSourceType) => {
      return isValidSource
        ? isValidSource(source) && isValidThemeSource(source)
        : isValidThemeSource(source)
    },
    [isValidSource]
  )

  return (
    <BackgroundPanel
      isDark={isDark}
      isValidSource={isValidThemeBackgroundSource}
      defaultMessage={defaultMessage}
      currentBackground={background}
      currentSource={currentSource}
      setCurrentBackground={setBackground}
      setCurrentSourceKey={setCurrentSourceKey}
      currentSourceKey={currentSourceKey}
    />
  )
}
