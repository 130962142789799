import {
  Alert,
  AlertIcon,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useCallback } from 'react'

import { DEFAULT_ACCENT_COLOR } from 'modules/theming/constants'

import { ColorOrGradientPicker } from '../ColorOrGradientPicker'
import { ColorPalettePicker } from '../ColorPalettePicker'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigAccentColorPanel = () => {
  const { label, state, dispatch, pop } = useThemeConfigNavigationContext()
  const { theme } = state

  const updateAccentColor = useCallback(
    (color: string | null) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: { themeProps: { accentColor: color ?? undefined } },
      }),
    [dispatch]
  )

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack>
        <FormControl data-testid="custom-theme-accent-color-picker">
          <FormLabel>Primary accent color</FormLabel>
          <FormHelperText mb={4}>
            You can set an accent color or gradient that will be applied to
            elements like links, buttons, and blockquotes.
          </FormHelperText>
          <ColorOrGradientPicker
            color={theme.accentColor}
            defaultColor={DEFAULT_ACCENT_COLOR}
            updateColor={updateAccentColor}
            gradient={theme.config.accentGradient}
            updateGradient={(accentGradient) =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: { themeConfig: { accentGradient } },
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Secondary accent colors</FormLabel>
          <FormHelperText mb={2}>
            Additional accent colors will be applied to decorative elements in
            your theme such as table dividers and timelines. If you don’t add
            any secondary accent colors, the primary accent color or gradient
            will be applied.
          </FormHelperText>
          <ColorPalettePicker
            value={theme.config?.secondaryAccentColors || []}
            updateValue={(secondaryAccentColors) =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: { themeConfig: { secondaryAccentColors } },
              })
            }
            maxColors={4}
          />
        </FormControl>
      </Stack>
      <Alert mt="6" fontSize="xs">
        <AlertIcon />
        <Text>
          Note: these colors may be lightened or darkened for contrast and
          accessibility.
        </Text>
      </Alert>
    </ThemeConfigNavigationWrapper>
  )
}
