import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DOC_DISPLAY_NAME } from '@gamma-app/ui'
import isEqual from 'lodash/isEqual'
import { nanoid } from 'nanoid'
import { useCallback, useMemo, useState } from 'react'

import { CustomImageProvider, UploadStatus } from 'modules/media'
import { ThemeBackgroundPanel } from 'modules/tiptap_editor/components/panels/ThemeBackgroundPanel'
import { MediaSourceType } from 'modules/tiptap_editor/extensions/media/MediaSources'
import {
  BackgroundOptions,
  BackgroundType,
  DEFAULT_ACCENT_IMAGE_BACKGROUND,
} from 'modules/tiptap_editor/styles/backgroundStyles'
import SalSpraypaint from 'publicImages/Sal-Spray.svg'

import { ACCENT_IMAGE_SOURCE_KEY } from '../constants'
import { Theme } from '../types'
import { isThemeDark } from '../utils/utils'
import { AccentImageGrid } from './AccentImageGrid'

type AccentImagePickerProps = {
  theme: Theme
  accentImages?: BackgroundOptions[]
  updateTheme: (config: Partial<Theme['config']>) => void
}

const isNotBlankBackgroundTypeSource = (source: MediaSourceType) =>
  source.backgroundType !== BackgroundType.NONE

const initialDefaultBackground = {
  ...DEFAULT_ACCENT_IMAGE_BACKGROUND,
  accentId: undefined,
}

export const AccentImagePicker = ({
  theme,
  updateTheme,
}: AccentImagePickerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [backgroundToEdit, setBackgroundToEdit] =
    useState<BackgroundOptions | null>(null)
  // Used to store the initial state of backgroundToEdit on edit/add, in order to determine if the state is 'dirty'.
  const [initialBackground, setInitialBackground] =
    useState<BackgroundOptions | null>(null)
  const isEditing = backgroundToEdit && !!backgroundToEdit.accentId
  const accentImages = theme.config.accentBackgrounds

  const onClickAdd = useCallback(() => {
    setInitialBackground(initialDefaultBackground)
    setBackgroundToEdit(initialDefaultBackground)
  }, [])

  const reset = useCallback(() => {
    setBackgroundToEdit(null)
    setInitialBackground(null)
  }, [])

  const handleAddOrSaveAccentImage = useCallback(() => {
    if (!backgroundToEdit) return

    // When saving the accent image, set the source as an accent image
    // so we can distinguish this as an accent image elsewhere in the editor.
    // Also save the original background source as `originalSource` so it can be used when later editing.
    const nextBackground = {
      ...backgroundToEdit,
      source: ACCENT_IMAGE_SOURCE_KEY,
      originalSource: backgroundToEdit.source,
      // Create a new accentId for both new images and edited ones, so that any accent images used in the editor
      // aren't incorrectly matched with the newly edited one.
      accentId: nanoid(),
    }

    const currentAccentImages = accentImages || []
    if (isEditing) {
      const indexToReplace = currentAccentImages.findIndex(
        (a) => a.accentId === backgroundToEdit.accentId
      )
      if (indexToReplace === -1) {
        // shouldn't get here
        console.warn('[AccentImagePicker] Could not find accent image to edit')
        return
      }
      updateTheme({
        accentBackgrounds: [
          ...currentAccentImages.slice(0, indexToReplace),
          nextBackground,
          ...currentAccentImages.slice(indexToReplace + 1),
        ],
      })
    } else {
      updateTheme({
        accentBackgrounds: [...currentAccentImages, nextBackground],
      })
    }

    reset()
  }, [accentImages, backgroundToEdit, isEditing, reset, updateTheme])

  const handleDeleteAccentImage = useCallback(
    (accentBackground: BackgroundOptions) => {
      const nextAccentImages = (accentImages || []).filter(
        (a) => a.accentId !== accentBackground.accentId
      )
      updateTheme({
        accentBackgrounds: nextAccentImages,
      })
    },
    [accentImages, updateTheme]
  )

  const handleEditAccentImage = useCallback(
    (accentBackground: BackgroundOptions) => {
      // When editing, re-set the source back to the original saved background
      // in `originalSource` (e.g. image.unsplash) so it can be used by the `ThemeBackgroundPanel`
      const backgroundWithOriginalSource = {
        ...accentBackground,
        source: accentBackground.originalSource,
      }
      setInitialBackground(backgroundWithOriginalSource)
      setBackgroundToEdit({
        ...accentBackground,
        source: accentBackground.originalSource,
      })
    },
    []
  )

  const handleSetBackground = useCallback(
    (accentBackground: BackgroundOptions) => {
      // make sure the accent image has finished uploading before allowing save
      if (accentBackground.source === CustomImageProvider.key) {
        if (accentBackground.image?.uploadStatus === UploadStatus.Uploading) {
          setIsLoading(true)
        } else if (
          accentBackground.image?.uploadStatus === UploadStatus.Done &&
          accentBackground.image?.uploadResultStep === 'optimized'
        ) {
          setIsLoading(false)
        } else if (
          accentBackground.image?.uploadStatus === UploadStatus.Error
        ) {
          setIsLoading(false)
        }
      }
      setBackgroundToEdit(accentBackground)
    },
    []
  )

  const isDirty = useMemo(
    () => !isEqual(backgroundToEdit, initialBackground),
    [backgroundToEdit, initialBackground]
  )
  const isDark = isThemeDark(theme)

  if (backgroundToEdit) {
    return (
      <>
        <ThemeBackgroundPanel
          setBackground={handleSetBackground}
          background={backgroundToEdit}
          isValidSource={isNotBlankBackgroundTypeSource}
          defaultMessage={
            <Alert>
              <AlertIcon />
              Set an accent image for the card layout.
            </Alert>
          }
          isDark={isDark}
        />

        <ButtonGroup pt={6} display="flex">
          <Button variant="ghost" onClick={reset}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={handleAddOrSaveAccentImage}
            isLoading={isLoading}
            isDisabled={!isDirty}
            flex={1}
          >
            {isEditing ? 'Save' : 'Add accent image'}
          </Button>
        </ButtonGroup>
      </>
    )
  }

  return (
    <>
      <Stack spacing={3}>
        <Button
          leftIcon={<FontAwesomeIcon icon={regular('plus')} />}
          alignSelf="flex-start"
          onClick={onClickAdd}
        >
          Add
        </Button>
        {!accentImages || accentImages?.length === 0 ? (
          <EmptyState />
        ) : (
          <AccentImageGrid
            accentImages={accentImages}
            isDark={isDark}
            handleDelete={handleDeleteAccentImage}
            handleItemClick={handleEditAccentImage}
          />
        )}
      </Stack>
      <Alert fontSize="xs" mt={6}>
        <AlertIcon />
        <Text>
          Accent images are a set of images associated with this theme that can
          be used as decoration in your {DOC_DISPLAY_NAME}.
        </Text>
      </Alert>
    </>
  )
}

const EmptyState = () => {
  return (
    <Box alignSelf="center" maxWidth={52}>
      <Image
        src={SalSpraypaint.src}
        alt="Sal, the Gamma mascot, flying and spraying pink and purple flecks of stars and slide deck icons"
        py={4}
      />
      <Text fontSize="sm" color="gray.700" textAlign="center">
        There’s nothing here yet.
      </Text>
    </Box>
  )
}
