import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SectionTitle } from '@gamma-app/ui'
import { useMemo } from 'react'

import {
  findClosestFontWeight,
  getSortedFontFileWeights,
  shouldBeItalicStyle,
} from 'modules/fonts/utils/utils'

import { FontPickerFonts } from '../types'
import { getFontName, isAdonisOrMackinac } from '../utils/fonts'
import { FontPickerWarningLabel } from './FontPickerWarningLabel'

type FontPickerProps = {
  value: string | undefined
  updateValue: (val: string | undefined) => void
  onFontUploadClick: () => void
  weight: string
  defaultFont: string
  fonts: FontPickerFonts
  customFontsEnabled: boolean
} & MenuButtonProps

export const FontPicker = ({
  value,
  updateValue,
  onFontUploadClick,
  weight = 'regular',
  defaultFont,
  fonts,
  customFontsEnabled,
  ...buttonProps
}: FontPickerProps) => {
  const onChange = (val: string) => updateValue(val || undefined)

  const { workspaceFonts, globalFonts } = fonts

  const [currentFont, selectedFontFamilyName, selectedFontNameToDisplay] =
    useMemo(() => {
      const allFonts = [...workspaceFonts, ...globalFonts]
      const currFont = allFonts.find((font) => font.id === value)
      const fontFamilyName = getFontName(allFonts, value)
      const fontDisplayName = getFontName(allFonts, value, false)
      return [currFont, fontFamilyName, fontDisplayName]
    }, [globalFonts, workspaceFonts, value])

  const hasWorkspaceFonts =
    customFontsEnabled &&
    workspaceFonts.filter((f) => !f.archived || f.id === currentFont?.id)
      .length > 0

  let isDeletedCustomFont = false
  // in order to display the fonts correctly in Safari, font weight and style should be provided and available
  let currentFontStyle: 'normal' | 'italic' = 'normal'
  let currentFontWeight = weight === 'bold' ? 700 : 400

  // global fonts have null workspaceId
  if (currentFont && currentFont.workspaceId !== null) {
    isDeletedCustomFont = currentFont.archived === true
    const currentFontFiles = [...(currentFont.fontFiles || [])]
    currentFontStyle = shouldBeItalicStyle(currentFontFiles)
      ? 'italic'
      : 'normal'
    const sortedFontWeights = getSortedFontFileWeights(currentFontFiles)
    currentFontWeight =
      findClosestFontWeight(sortedFontWeights, weight === 'bold' ? 700 : 400) ||
      400
  }

  return (
    <Menu matchWidth isLazy>
      <MenuButton
        size="lg"
        as={Button}
        borderRadius="md"
        rightIcon={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            transform="shrink-6"
          />
        }
        variant="toolbar"
        px={4}
        h={10}
        w="100%"
        border="1px solid"
        borderColor="gray.200"
        {...buttonProps}
      >
        {customFontsEnabled && isDeletedCustomFont ? (
          <FontPickerWarningLabel
            label={selectedFontNameToDisplay || ''}
            fontFamily={`"${selectedFontFamilyName}"`}
            labelColor={value ? 'gray.800' : 'gray.400'}
            fontStyle={currentFontStyle}
            fontWeight={currentFontWeight}
          />
        ) : (
          <Text
            textAlign="left"
            fontFamily={`"${selectedFontFamilyName || defaultFont}"`}
            fontWeight={currentFontWeight}
            color={value ? 'gray.800' : 'gray.400'}
            fontStyle={currentFontStyle}
          >
            {selectedFontNameToDisplay || `${defaultFont} (Gamma default)`}
          </Text>
        )}
      </MenuButton>
      <MenuList maxH="320px" overflow="auto" zIndex="dropdown">
        <MenuOptionGroup type="radio" value={value || ''} onChange={onChange}>
          {hasWorkspaceFonts && (
            <SectionTitle m={2}>Uploaded fonts</SectionTitle>
          )}
          {hasWorkspaceFonts &&
            workspaceFonts.map((font) => {
              const isNotSelectedFont = font.id !== value
              const isDeletedAndNotSelected = font.archived && isNotSelectedFont
              const isEmptyAndNotSelected =
                font.fontFiles?.length === 0 && isNotSelectedFont

              if (
                !font.fontFiles ||
                isEmptyAndNotSelected ||
                isDeletedAndNotSelected
              ) {
                // Don't show fonts that are empty or archived, unless selected
                return null
              }
              const fontStyle = shouldBeItalicStyle(font.fontFiles)
                ? 'italic'
                : 'normal'

              if (isDeletedCustomFont && font.id === value) {
                return (
                  <MenuItemOption key={font.id} value={font.id} disabled>
                    <FontPickerWarningLabel
                      fontFamily={font.name}
                      label={font.name}
                      fontStyle={fontStyle}
                      description="This font family has been deleted. If you switch to a different font and save, you won’t be able to switch back without re-uploading this font."
                    />
                  </MenuItemOption>
                )
              }

              return (
                <MenuItemOption key={font.id} value={font.id}>
                  <Text
                    fontFamily={`"${font.name}"`}
                    fontSize="lg"
                    fontWeight={weight}
                    fontStyle={fontStyle}
                  >
                    {font.name}
                  </Text>
                </MenuItemOption>
              )
            })}

          {hasWorkspaceFonts && (
            <>
              <MenuDivider />
              <SectionTitle m={2}>Built-in fonts</SectionTitle>
            </>
          )}
          <MenuItemOption value={''}>
            <Text
              fontFamily={defaultFont}
              fontSize="lg"
              fontWeight={weight}
              color="gray.400"
            >
              Gamma default
            </Text>
          </MenuItemOption>
          {globalFonts &&
            globalFonts.map((font) => (
              <MenuItemOption key={font.id} value={font.id}>
                <Text
                  fontFamily={
                    isAdonisOrMackinac(font.id)
                      ? `"${font.id}"`
                      : `"${font.name}"`
                  }
                  fontSize="lg"
                  fontWeight={weight}
                >
                  {font.name}
                </Text>
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
        {customFontsEnabled && (
          <Button
            width="calc(100% + var(--chakra-space-4))"
            variant="plain"
            position="sticky"
            bottom={-2}
            m={-2}
            mt={2}
            p={2}
            borderX="none"
            borderBottom="none"
            fontSize="sm"
            borderRadius="none"
            onClick={onFontUploadClick}
            leftIcon={
              <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
            }
          >
            Upload fonts
          </Button>
        )}
      </MenuList>
    </Menu>
  )
}
