import { Stack } from '@chakra-ui/react'

import { CSSinJSFormControl } from 'modules/code_editor/components/CSSinJSFormControl'

import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigAdvancedPanel = () => {
  const { pop, state, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack>
        <CSSinJSFormControl
          label="Content CSS"
          initialValue={theme.config?.contentStyles}
          updateValue={(json) =>
            dispatch({
              type: 'THEME_UPDATE_THEME_CONFIG',
              data: { themeConfig: { contentStyles: json } },
            })
          }
        />
        <CSSinJSFormControl
          label="Card effects"
          initialValue={theme.config?.containerStyles}
          updateValue={(json) =>
            dispatch({
              type: 'THEME_UPDATE_THEME_CONFIG',
              data: { themeConfig: { containerStyles: json } },
            })
          }
          helperText="Applies to expanded cards."
        />
      </Stack>
    </ThemeConfigNavigationWrapper>
  )
}
