import { Box, Button, Flex } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { resetButtonStyles } from './ThemeConfigNavigationMenuButton'

type ThemeConfigNavigationWrapperProps = {
  label: string
  onBackClick: () => void
  children: React.ReactNode
}
export const ThemeConfigNavigationWrapper = ({
  label,
  onBackClick,
  children,
}: ThemeConfigNavigationWrapperProps) => {
  return (
    <>
      <Flex
        data-testid="theme-config-back-button"
        as={Button}
        {...resetButtonStyles}
        p={4}
        borderBottomWidth="1px"
        bg="gray.50"
        _hover={{
          bg: 'gray.200',
        }}
        onClick={onBackClick}
      >
        <Box color="gray.600" mr={2}>
          <FontAwesomeIcon icon={regular('chevron-left')} />
        </Box>
        <Box fontWeight="600">{label}</Box>
      </Flex>
      <Box p={6}>{children}</Box>
    </>
  )
}
