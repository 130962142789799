import { AccentImagePicker } from '../AccentImagePicker'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigAccentImagesPanel = () => {
  const { pop, state, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <AccentImagePicker
        theme={theme}
        updateTheme={(config) =>
          dispatch({
            type: 'THEME_UPDATE_THEME_CONFIG',
            data: { themeConfig: { ...config } },
          })
        }
      />
    </ThemeConfigNavigationWrapper>
  )
}
