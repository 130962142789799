import { createContext, useContext } from 'react'

import { NavigationStackComponent } from './types'

type NavigationStackContextType = {
  push: (comp: NavigationStackComponent) => void
  pop: () => void
}

export const NavigationStackContext = createContext<NavigationStackContextType>(
  {} as any
)

export function useNavigationStackContext() {
  const ctx = useContext(NavigationStackContext)
  return ctx
}
